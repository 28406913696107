<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
      <div class="header-label col-lg-5 col-12">Birthdays</div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link custom-navbar-navlink " id="todaysbday-details-tab" data-bs-toggle="tab"
        data-bs-target="#todaysbdayalldetail" type="button" role="tab" aria-controls="vehicle-details"
        aria-selected="false" :disabled="loading" @click="redirecttodaysbday()"><i class="pi pi-calendar me-1" :style="{ fontSize: '0.7rem' }"></i>Today
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link custom-navbar-navlink active" id="tomorrowbday-details-tab" data-bs-toggle="tab" data-bs-target="#tomorrowbdayalldetail"
        type="button" role="tab" aria-controls="customer-details" aria-selected="true" @click="redirecttomorrowbday()" :disabled="loading">
        <i class="pi pi-calendar me-1" :style="{ fontSize: '0.7rem' }"></i>Tomorrow
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link custom-navbar-navlink" id="thisweekbday-details-tab" data-bs-toggle="tab" data-bs-target="#thisweekbdayalldetail"
        type="button" role="tab" aria-controls="customer-details" aria-selected="false" @click="redirectthisweekbday()" :disabled="loading">
        <i class="pi pi-calendar me-1" :style="{ fontSize: '0.7rem' }"></i>This Week
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link custom-navbar-navlink" id="thismonthbday-details-tab" data-bs-toggle="tab" data-bs-target="#thismonthbdayalldetail"
        type="button" role="tab" aria-controls="customer-details" aria-selected="false" @click="redirectthismonthbday()" :disabled="loading">
        <i class="pi pi-calendar me-1" :style="{ fontSize: '0.7rem' }"></i>This Month
      </button>
    </li>
  </ul>
  <div class="tab-content mt-2" id="myTabContent">
    <div class="tab-pane fade " id="todaysbdayalldetail" role="tabpanel"
      aria-labelledby="todaysbday-details-tab">
    today
  </div>
    <div class="tab-pane fade show active" id="tomorrowbdayalldetail" role="tabpanel" aria-labelledby="tomorrowbday-details-tab">
      <div class="custom-ultima-datatable" style="height: calc(100vh - 185px)">
      <DataTable :value="birthdateList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex" :paginator="true"
        :rows="items_per_page" :lazy="true" dataKey="ak1" :rowHover="true" :totalRecords="totalRecords" @page="changePage($event)"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        :loading="loading">
        <template v-if="!loading" #empty>No records found.</template>
        <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
        <Column field="Model" header="Voter Name" headerStyle="width: 60%" bodyStyle="width: 60%">
          <template #body="{ data }">
              <div class="label-subheading text-capitalize">
                {{ data.ak5 ? data.ak5 : "N/A" }}</div>
          </template>
        </Column>
        <Column field="mob" header="Birth Date" headerStyle="width: 20%" bodyStyle="width: 20%">
          <template #body="{ data }">
              <div class="from-label-value">{{ format_date(data.ak18)}}</div>
          </template>
        </Column>
        <Column field="teamlead" header="Mobile Number" headerStyle="width: 20%" bodyStyle="width: 20%">
          <template #body="{ data }">
            <div>{{ data.ak24 ? data.ak24 : "N/A" }}
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    </div>
    <div class="tab-pane fade" id="thisweekbdayalldetail" role="tabpanel" aria-labelledby="thisweekbday-details-tab">
      thisweekbday
    </div>
    <div class="tab-pane fade" id="thismonthbdayalldetail" role="tabpanel" aria-labelledby="thismonthbday-details-tab">
      thismonthbday
    </div>
  </div>
  </template>
  <script>
  import ApiService from "../../service/ApiService";
  import moment from 'moment';
  export default {
    data() {
      return {
        birthdateList: null,
        totalRecords: 0,
        page_no: 0,
        items_per_page: 30,
        loading: false,
      };
    },
  
    mounted() {
      this.getVoterBirthdateList({date_type: 2});
    },
  
    ApiService: null,
    interval: null,
  
    created() {
      this.ApiService = new ApiService();
    },

    methods: {
      
        getVoterBirthdateList(event) {
        this.loading = true;
        this.ApiService.getVoterBirthdateList(event).then((items) => {
          if (items.success == true) {
            this.birthdateList = items.data;
            this.totalRecords = items.count;
            this.loading = false;
          } else {
            this.loading = false;
            this.birthdateList = null;
            this.totalRecords = 0;
          }
        });
      },
  
      changePage(event) {
        this.page_no = event.page;
        this.getVoterBirthdateList({
          page_no: this.page_no,
          items_per_page: this.items_per_page,
          date_type: 2
        });
      },
      format_date(value) {
        if (value) {
            return moment(String(value)).format("DD/MM/YYYY");
        } else {
            return 'N/A';
        }
    },
    redirecttodaysbday() {
      this.$router.push("/birthdays/todays");
    },
    redirecttomorrowbday() {
      this.$router.push("/birthdays/tomorrow");
    },
    redirectthisweekbday() {
      this.$router.push("/birthdays/thisweek");
    },
    redirectthismonthbday() {
      this.$router.push("/birthdays/thismonth");
    },
    },
  };
  </script>
  
  <style scoped>
  </style>